//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-32:_8188,_7452,_5368,_6668,_1948,_3984,_7328,_5412;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-32')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-32', "_8188,_7452,_5368,_6668,_1948,_3984,_7328,_5412");
        }
      }catch (ex) {
        console.error(ex);
      }