export default {
  CHIPS_DISCARD: '<span class="icon-cancel"></span>',
  PRODUCT_REPEATER_CLASS: 'product-items product-items-4',
  SHOW_RESULTS_CLASS: 'btn btn-default',
  FACET_DIALOG_BUTTON_CONTAINER_CLASS: 'btn btn-default',
  FACET_DIALOG_TITLE: 'Filter Options',
  FACET_INPUT_CLASS: 'form-control',

  SEARCH_BOX_PLACEHOLDER: 'Search for a Product',
  SEARCH_BOX_FORM_CLASS: 'search-form',
};
